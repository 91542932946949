<template>
  <div id="requirement">
    <!-- 左侧广告位 -->
    <div class="leftWrap">
      <div class="contact">
        <div class="code">
          <img src="../../assets/shopImg/req/erweima.png" alt>
          <p class="biaoyu f14">
            关注孔雀有礼
            随时随地为您服务
          </p>
        </div>
        <div class="tel flex start align-center">
          <img src="../../assets/shopImg/req/kefu.png" alt>
          <div class="telText f16">
            <p>全国客服热线</p>
            <span>400-1500-980</span>
          </div>
        </div>
      </div>
      <div class="recommand">
        <div class="head">推荐商家</div>
        <div class="box flex start align-center" v-for="(item,index) in storeLists" :key="index">
          <img :src="item.storeLogo | imagePath" alt>
          <div class="f16">
            <p class="storeName">{{item.storeName}}</p>
<!--            <span class="distance f12">距您15.2公里</span>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      storeLists:[], // 推荐商家
    };
  },
  mounted(){
    this.getStoreLists()
  },
  methods:{
    getStoreLists() {
      this.$http.get('/store/getStores')
      .then(res=>{
        const { data } = res.data
        this.storeLists = data;
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.leftWrap {
  width: 223px;
  height: auto;
  min-height: 500px;
  margin-right: 20px;
  .contact {
    padding: 20px 30px;
    margin-bottom: 20px;
    background: #fff;
    .code {
      width: 120px;
      margin: 0 auto 34px;

      img {
        width: 127px;
        height: 127px;
        margin-bottom: 20px;
      }

      .biaoyu {
        // width: 116px;
        width: 120px;
        margin-left: 6px;
        text-align: center;
      }
    }

    .tel {
      img {
        width: 23px;
        height: 25px;
      }

      .telText {
        margin-left: 10px;
        span {
          color: #d82229;
        }
      }
    }
  }

  .recommand {
    background: #f3f3f3;
    .head {
      height: 40px;
      line-height: 40px;
      background: #d82229;
      text-align: center;
      color: #fff;
    }
    .box {
      padding: 20px 20px 30px;
      background: #fff;
      margin: 10px  auto;
      img {
        width: 54px;
        height: 54px;
        margin-right: 20px;
        flex-shrink: 0;
      }
      .distance {
        padding-top: 15px;
        color: #666;
      }
    }
  }
}
</style>

