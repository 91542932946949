<template>
    <div id="requirementPage">
        <div class="requireBody">
            <div class="requireContainer row">
                <!-- 左侧广告位 -->
               <Requirement></Requirement>
               <!-- 右侧内容 -->
               <div class="reContent">
                   <div class="reSuccess">
                       <div class="flex align-center flex-center">
                            <img src="../../../assets/shopImg/req/gongxinintubiao.png" alt="">
                            <span class="f28">恭喜您!</span>
                       </div>
                       <div class="sec f24">需求发布成功</div>
                       <div class="f14 l20">
                           您的需求已发布，我们将会匹配优质的商家为您服务需求发布期间，您会收到商家的电话沟通，请保持电话畅通
                       </div>
                       <div class="row">
                           <div class="submit" @click="gocaseDetail">查看需求详情</div>
                            <div class="submit return" @click="goHomePage">返回首页</div>
                       </div>
                   </div>
               </div>
            </div>  
        </div>
    </div>
</template>
<script>
import Requirement from "@/components/common/requirement";
export default {
    components: { Requirement },
    data(){
        return {
            id:""
        }
    },
    created(){
        this.id=this.$route.query.id;
    },
    methods:{
        goHomePage(){
            this.$router.push({
                path:'/'
            })
        },
        gocaseDetail(){
            let id = this.id;
            this.$router.push({
                path: '/quoteMerchant/'+id
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/change.scss';
.reContent {
    background-color: #fff;
    padding: 128px 300px 200px;
    margin: 0 auto;
    text-align: center;
    .reSuccess {
        width:400px;
        .reTtile {
            margin:0 auto;
        }
        img {
            width: 40px;
            height: 40px;
            margin-right: 19px;
        }
        .sec {
            margin: 20px auto 30px;
        }
        .l20 {
            line-height: 20px;
            margin-bottom: 60px;
        }

        .submit {
            width: 140px;
            height: 40px;
            margin-right: 48px;
            line-height: 40px;
            color: #fff;
            background: #0db168;
            cursor: pointer;
        }

        .return {
            margin-right:0;
            border:1px solid #0db168;
            background: #fff;
            color: #0db168;
        }
    }
}
</style>